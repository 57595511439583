.event-calendar-filter {
  display: flex;
  height: 40vh;
  width: 100%;
  position: absolute;
  background-color: #f7f5f5;
  border-radius: 5px;
  top: 100%;
  right: 0;
  padding: 1%;
  color: #111;
}

.calendar-filter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 110%;
  padding: 2% 0;
}

.calendar-filter-options {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: relative;
  height: 18%;
  padding: 0 2%;
}

.calendar-filter-options > h4 {
  font-size: 12px;
  white-space: nowrap;
}

.calendar-filter-options-div {
  width: 13%;
  cursor: pointer;
  background: none;
  outline: none;
  height: 80%;
}

.choose-month {
  display: grid;
  width: 50%;
  aspect-ratio: 3/4;
  grid-template-columns: repeat(3, 33%);
  position: absolute;
  top: 10%;
  left: 14%;
}

.month {
  font-size: 2.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #222121;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease;
}

.choose-year-wrapper {
  height: 30vh;
  width: 35%;
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 13%;
}

.choose-year {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.year {
  height: 5vh;
  background-color: #f7f5f5;
  color: #222121;
  text-align: center;
  font-weight: 700;
  transition: 0.2s ease;
  cursor: pointer;
  width: 80%;
}

.calendar-filter-wrapper {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, 14%);
  justify-content: center;
  align-items: center;
  height: 82%;
}

.calendar-filter-header,
.calendar-filter-day {
  text-align: center;
  font-size: 10px;
}

.calendar-filter-day {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.calendar-day {
  height: 100%;
}

.editor-calendar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}
