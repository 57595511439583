.registration-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 10%;
  gap: 10%;
}

.registration-choose {
  height: 30%;
  width: 100%;
}

.registration-text {
  width: 100%;
  padding: 5%;
  text-decoration: none;
  color: inherit;
}
