.msg-forward {
  position: absolute;

  width: 99.9vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5%;
  background-color: rgb(153, 153, 153, 0.8);
}

.msg-forward.mini {
  left: 0;
}

.msg-forward-wrapper {
  height: 70%;
  width: 77%;
  display: flex;
  flex-direction: column;
  padding: 2%;
  background-color: #e6e3e3;
  border-radius: 5px;
  align-items: center;
}

.msg-forward-header {
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.msg-cancel {
  height: 80%;
  aspect-ratio: 1 / 1;
}

.msg-forward-chats {
  height: 80%;
  width: 100%;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  gap: 2%;
}

.msg-user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 5%;
  background-color: #007bff;
  color: #fff;
  padding: 2%;
  border-radius: 5px;
  cursor: pointer;
}

.msg-user.selected {
  background-color: #28a745;
}

.msg-forward-btn {
  height: 10%;
  width: 40%;
}
