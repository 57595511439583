.user-manual-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 11.11vh);
  position: absolute;
  background-color: white;
  z-index: 500;
}
.user-manual-container {
  height: 15%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
}
.user-manual-container > img,
.user-manual-pdf {
  object-fit: contain;
  width: 15%;
  aspect-ratio: 1/1;
}

.user-manual-pdf-img {
  width: 100%;
  aspect-ratio: 1/1;
}

.user-manual-text {
  display: flex;
  width: 100%;
  height: 85%;
  padding: 0 5%;
  overflow-y: auto;
}
