.wrapper-translate
{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 5%;
    justify-content: space-between;
}
.text-translate{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 25%;
    justify-content: space-evenly;
}
.text-translate-title
{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}
.text-translate-body
{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
}
.input-mail
{
    display: flex;
    height: 5%;
    gap:2%
}
.input-mail >img
{
    height: 100%;
    object-fit:contain;
    width: 30%;
}
.input-mail > input
{
    height: 100%;
    width: 70%;
    font-size: 25px;
}
.language-translate-button
{
    width: 100%;
    height: 10%;
}
.wrapper-table-user
{
    display: flex;
    width: 100%;
    height: 15%;
}
.table-language
{
    height: 100%;
    width: 70%;
}
.language-translate-start-button
{
    height: 100%;
    width: 30%;
}
.first-column
{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
}

td,th
{
    border: 1px solid black;
    font-size: 20px;
    
}
table
{
    border-collapse: collapse;
}
