.message-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(6.4, 6.4, 6.4, 0.5);
  position: absolute;
  top: 0;
}

.message-container {
  height: 30%;
  width: 90%;
  background-color: white;
  border-radius: 5px;
  position: relative;
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 5%;
}

.cancel-message {
  position: absolute;
  top: 7.5%;
  right: 5%;
  height: 10%;
  aspect-ratio: 1/1;
}

.leave-button {
  width: 40%;
  height: 30%;
}
.leave-application {
  text-transform: capitalize;
}
.message-text {
  text-align: center;
}
