.folder-modal {
  position: absolute;
  top: 15vh;
  left: 5%;
  height: 81vh;
  width: 90%;
  background-color: #888;
  padding: 1%;
}

/* header */

.envelope-header {
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.envelope-header > h4 {
  font-size: 17px;
  font-weight: 600;
  color: black;
}

.envelope-reset {
  margin-left: auto;
  margin-right: 10%;
}

.envelope-header-sort {
  display: flex;
  align-items: center;
  height: 100%;
  width: 35%;
  margin-left: auto;
}

.sort-up {
  height: 80%;
  width: 50%;
  position: relative;
}

.triangle-sort {
  width: 0;
  height: 0;
  border-bottom: solid 1vh transparent;
  border-top: solid 1vh transparent;
  border-left: solid 1vh black;
  border-right: solid 1vh transparent;
  transform: rotate(90deg);
  position: absolute;
  top: calc(50% - 0.4vh);
}

.triangle-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.triangle-sort.active {
  transform: rotate(270deg);
  top: 25%;
}

.envelope-header-filter {
  width: 35%;
  height: 80%;
}

.filter-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.triangle-envelope {
  width: 0;
  height: 0;
  border-bottom: solid 1vh transparent;
  border-top: solid 1vh transparent;
  border-left: solid 1vh black;
  border-right: solid 1vh transparent;
  transform: rotate(90deg);
  position: absolute;
  top: 40%;
}

.triangle-envelope.active {
  transform: rotate(270deg);
  top: 25%;
}

.wraper-triangle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 30%;
  height: 100%;
}

/* add document */

.add-document {
  height: 6%;
  width: 10%;
}

/* messages */

.envelope-documents {
  display: flex;
  flex-direction: column;
  height: 75%;
  justify-content: flex-start;
  width: 100%;
  gap: 1.25%;
}

.envelope-message:last-child {
  border-bottom: 1px solid gray;
}

.envelope-message {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 4%;
  height: 11%;
  max-height: 11%;
  overflow: hidden;
  border-top: 1px solid gray;
  transition: 0.2s ease;
  cursor: pointer;
}

.envelope-message:hover {
  background-color: #fff;
}

.envelope-message > span {
  margin-left: auto;
  color: #111;
  font-size: 10px;
  font-weight: 700;
  height: 40%;
}

.envelope-message > p {
  color: #111;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  height: 60%;
}

/* pagination */

.envelope-pagination {
  width: 100%;
  height: 9%;
  display: flex;
  justify-content: space-between;
}

.envelope-pagination-btn {
  height: 100%;
  aspect-ratio: 1 / 1;
}

.envelope-pagination-list {
  display: flex;
  justify-content: center;
  flex: 1;
  height: 100%;
}

.envelope-page {
  height: 100%;
  aspect-ratio: 1 / 1;
}

.envelope-page.combo-box {
  aspect-ratio: 1.5 /1;
  position: relative;
}

.envelope-combo-box {
  position: absolute;
  height: 15vh;
  width: 100%;
  overflow-y: auto;
  bottom: 100%;
}

.wraper-envelope-combo-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.envelope-combo-box-option {
  height: 5vh;
}

/* single document */

.basic-document {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 3%;
  height: 19%;
  max-height: 20%;
  transition: 0.2s ease;
  cursor: pointer;
  position: relative;
  background-color: white;
  color: #111;
}

.basic-document > .header,
.basic-document > .footer {
  position: relative;
  width: 100%;
  height: 50%;
}

.header > span {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  font-weight: 900;
}

.header > h4 {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 15px;
}

.footer > span {
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.basic-document-options {
  display: flex;
  width: 50%;
  height: 100%;
  position: absolute;
  align-items: center;
  right: 0;
  bottom: 0;
}

.basic-document-button {
  width: 33%;
  height: 90%;
}

.document-view-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}
.wrapper-header {
  display: flex;
  width: 100%;
  height: 10%;
}
.document-view {
  height: 100%;
  width: 100%;
}
.document-title {
  display: flex;
  align-items: center;
  padding-left: 2%;
  font-size: 20px;
  font-weight: 700;
  width: 85%;
  height: 100%;
}
.full-screen {
  display: flex;
  height: 100%;
  aspect-ratio: 1/1;
  color: gray;
}
.basic-document-download-link {
  height: 100%;
  padding: 1%;
  width: max-content;
  align-self: flex-start;
  margin-bottom: 1%;
  white-space: nowrap;
  text-align: start;
  display: flex;
  align-items: center;
  margin-left: 5%;
  transition: 0.2s ease;
  border-radius: 5px;
}

.folder-modal > * .mobile__pdf__container__header {
  position: absolute;
  z-index: 200;
  width: 100%;
  top: 12%;
  left: 0;
}

.folder-modal > * .mobile__pdf__container #viewerContainer {
  top: 17% !important;
  height: 60vh !important;
}

.folder-modal > * .mobile__pdf__container footer {
  width: 100%;
}

.folder-modal > * .pdfViewer .canvasWrapper {
  width: unset !important;
  height: unset !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.folder-modal > * .mobile__pdf__container footer {
  position: absolute !important;
}

/* SEARCH FILTER */

.envelope-wrapper-filters {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: white;
  justify-content: space-around;
  width: 100%;
  height: 22vh;
  top: 100%;
  gap: 3%;
  z-index: 200;
}

/*search*/
.envelope-filters-search {
  display: flex;
  width: 100%;
  height: 30%;
  align-items: center;
  justify-content: center;
  padding: 0 2%;
}

.envelope-filters-search-button {
  display: flex;
  height: 100%;
  width: 20%;
  align-items: center;
}
.envelope-filters-search > input {
  height: 79.8%;
  width: 80%;
  margin-top: 3px;
  border-radius: 5px;
  border: 1px solid gray;
}

/*date */
.envelope-filters-date {
  display: flex;
  flex-direction: column;
  padding: 2%;
  width: 100%;
  height: 65%;
}

.envelope-filters-date-from-to {
  display: flex;
  height: 50%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.envelope-filters-date-from-to > .first-span {
  text-align: center;
  color: black;
  width: 20%;
}

.envelope-filters-date-from-to > .second-span {
  border: 1px solid gray;
  border-radius: 5px;
  width: 60%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #111;
}

.envelope-filters-date-button {
  height: 100%;
  width: 20%;
}
