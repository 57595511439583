.terms-of-use-wrapper {
  width: 90%;
  height: 100%;
  padding: 2%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 2%;
}
.close-terms {
  position: absolute;
  top: 5%;
  right: 5%;
  height: 7%;
  aspect-ratio: 1/1;
}

.title-terms {
  width: 50%;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}

.send-terms {
  height: 10%;
  width: 80%;
}

.content-terms {
  height: 70%;
  width: 100%;
  padding: 0 5%;
}

.send-terms-span {
  height: 100%;
  width: 100%;
  font-size: 15px;
  padding: 0 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}
