:root {
  --background-color: #f9f9f9ff;
  --border-dark: #999999ff;
  --border-light: #f2f2f2ff;
  --validate-valid: #00b055ff;
  --validate-invalid: #ff3519ff;
  --button-idle: #ccccccff;
  --button-active: #e6e6e6ff;
}

button {
  border-top: var(--border-light) 2px solid;
  border-bottom: var(--border-dark) 2px solid;
  border-left: var(--border-dark) 2px solid;
  border-right: var(--border-light) 2px solid;
  background: var(--button-idle);
  width: 100%;
}

.send-or-copy-mail-input {
  border-top: var(--border-dark) 2px solid;
  border-bottom: var(--border-light) 2px solid;
  border-left: var(--border-light) 2px solid;
  border-right: var(--border-dark) 2px solid;
  height: 10mm;
  margin: 10px 0;
  font-size: 1.3em;
  width: 80vw;
}

.send-or-copy-mail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5%;
  margin: 25px 40px;
}

.message-top {
  padding: 12px 20px;
  border: 1px solid gainsboro;
}

.send-to-me-btn {
  width: 100%;
  height: 10%;
}

.send-to-me-btn button {
  padding: 20px;
  font-size: 1.3em;
}

.message-bottom {
  padding: 12px 20px;
  border: 1px solid gainsboro;
  background-color: var(--border-light);
  margin-top: 30px;
}

.copy-mail-content-btn {
  width: 100%;
}

.copy-mail-content-btn button {
  padding: 20px;
  margin-top: 30px;
  font-size: 1.3em;
}
