* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  max-width: 100vw;
  background: #f9f9f9ff;
}

.app {
  width: 100%;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  background-color: rgb(250, 250, 250);
}
