:root {
  --background-color: #f9f9f9ff;
  --border-dark: #999999ff;
  --border-light: #f2f2f2ff;
  --validate-valid: #00b055ff;
  --validate-invalid: #ff3519ff;
  --button-idle: #ccccccff;
  --button-active: #e6e6e6ff;
}

button {
  border-top: var(--border-light) 2px solid;
  border-bottom: var(--border-dark) 2px solid;
  border-left: var(--border-dark) 2px solid;
  border-right: var(--border-light) 2px solid;
  background: var(--button-idle);
  width: 100%;
}

.enter-code-input {
  border-top: var(--border-dark) 2px solid;
  border-bottom: var(--border-light) 2px solid;
  border-left: var(--border-light) 2px solid;
  border-right: var(--border-dark) 2px solid;
  height: 10mm;
  margin: 10px 0;
  font-size: 1.3em;
  width: 87vw;
}

.enter-code-container h1 {
  font-size: 3.2em;
  text-align: center;
  font-weight: 500;
  letter-spacing: 2.5px;
}

.enter-code-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0px 30px;
  gap: 5%;
}

.input-group {
  display: flex;
  justify-content: space-between;
}

.input-group input {
  width: 75px;
  height: 95px;
  font-size: 4em;
  text-align: center;
}

.enter-code-container button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
}

.enter-code-container img {
  width: 60px;
}

.message-verif-code {
  padding: 12px 20px;
  border: 1px solid gainsboro;
  margin-top: 50px;
}
