:root {
  --background-color: #f9f9f9ff;
  --border-dark: #999999ff;
  --border-light: #f2f2f2ff;
  --validate-valid: #00b055ff;
  --validate-invalid: #ff3519ff;
  --button-idle: #ccccccff;
  --button-active: #e6e6e6ff;
}

.dos-btn {
  border-top: 4px solid var(--border-light);
  border-left: 4px solid var(--border-dark);
  border-bottom: 4px solid var(--border-dark);
  border-right: 4px solid var(--border-light);
  cursor: pointer;
  background-color: var(--button-idle);
  color: black;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8vh;
}

.dos-btn-active {
  border-bottom: 4px solid var(--border-light);
  border-right: 4px solid var(--border-dark);
  border-top: 4px solid var(--border-dark);
  border-left: 4px solid var(--border-light);
  cursor: pointer;
  background-color: var(--button-active);
  color: #2d2d2d;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8vh;
}

.neo-btn {
  color: black;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px rgba(0, 0, 0, 0.3), 4px 4px 5px 0px rgba(0, 0, 0, 0.3),
    inset 0px 0px 0px 0px #fff9, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.3),
    inset 0px 0px 0px 0px #fff9, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.2s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  border: none;
  margin: 0 2px;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1.8vh;
}

.neo-btn-active {
  margin: 0 2px;
  color: black;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: box-shadow 0.2s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  box-shadow: 0px 0px 0px 0px #fff9, 0px 0px 0px 0px #fff9,
    0px 0px 0px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 0px rgba(0, 0, 0, 0.3),
    inset -7px -7px 20px 0px #fff9, inset -4px -4px 5px 0px #fff9,
    inset 7px 7px 20px 0px rgba(0, 0, 0, 0.3),
    inset 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  cursor: pointer;
  font-size: 1.8vh;
}

.neo-btn-active img,
.neo-btn img {
  animation: clicked 0.2s ease;
  height: 60%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.dos-btn-active img,
.dos-btn img {
  animation: clicked 0.2s ease;
  height: 60%;
  aspect-ratio: 1/1;
}

.dos-btn.not-correct,
.neo-btn.not-correct {
  animation: wrong-btn;
  animation-duration: 0.5s;
}

.dos-btn.correct,
.neo-btn.correct {
  background-color: green;
}

.dos-btn.correct-btn {
  animation: correct-btn;
  animation-duration: 0.5s;
}

@keyframes clicked {
  100% {
    transform: scale(0.95);
  }
}

@keyframes wrong-btn {
  100% {
    background-color: var(--validate-invalid);
  }
}

@keyframes correct-btn {
  100% {
    background-color: var(--validate-valid);
  }
}
