.wraper-filter-category {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -40%;
  left: -19vw;
  height: 86%;
  width: 88vw;
  background-color: #c4bebe;
  overflow-y: auto;
}

.wraper-viewer-category {
  display: flex;
  flex-direction: column;
  position: absolute;
  /* top: -40%; */
  /* left: -20vw; */
  height: 86%;
  width: 96%;
  background-color: #c4bebe;
  overflow-y: auto;
}

.category-filter-modal-item-button {
  width: 100%;
  height: 7vh;
}

.category-filter-modal-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 5%;
}

.category-filter-modal-item-color {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}
.category-filter-modal-item-letter {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 700;
}
.category-filter-modal-item-name {
  font-size: 15px;
  font-weight: 700;
}
.category-filter-modal-item-color-letter {
  width: 50%;
  aspect-ratio: 1/1;
}
