.left-side-message-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1%;
  background-color: #999999;
}

.message-header {
  display: flex;
  height: 8%;
  width: 100%;
}

.message-header.blocked {
  pointer-events: none;
}

.message-input {
  width: 60%;
  flex: 1;
  padding: 0 2%;
  outline: none;
  pointer-events: auto;
}

.btn-up,
.btn-down,
.btn-refresh,
.btn-send {
  height: 100%;
  aspect-ratio: 1 / 1;
}

.message-chat {
  height: 84%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 2%;
}

.msg-reply {
  width: 100%;
  padding: 5%;
  display: flex;
  background-color: #999999;
}

.msg-reply-information {
  display: flex;
  width: 90%;
  flex-direction: column;
  padding-left: 5%;
}
.msg-reply-information > span:first-child {
  font-weight: 700;
}

.msg-select {
  border: none;
  outline: none;
  cursor: pointer;
  align-self: flex-end;
  background-color: #111;
  color: white;
  height: 50%;
  aspect-ratio: 1/1;
  border-radius: 5px;
}

.message {
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 3% 5%;
  align-items: flex-start;
  color: #111;
  border-radius: 10px;
  font-size: 12px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.message.current {
  background-color: #fff;
  align-self: flex-end;
  border-top-right-radius: 0;
  margin-right: 5%;
}

.message.other {
  background-color: #007bff;
  align-self: flex-start;
  border-top-left-radius: 0;
  margin-left: 5%;
}

.message.current.selected,
.message.other.selected {
  background-color: #28a745;
}
.message.current.checked,
.message.other.checked {
  background-color: yellow;
}

.msg-text {
  margin: 2% 0;
  font-size: 13px;
  font-weight: 500;
}

.message-footer {
  height: 8%;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  background-color: white;
}

.message-reply {
  position: absolute;
  bottom: 100%;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}
.message-reply-information {
  flex: 1;
  font-size: 12px;
  padding-left: 1%;
  background-color: rgb(243, 204, 204);
  color: #000;
}

.message-sender {
  font-weight: 700;
}

.msg-footer-select-left {
  display: flex;
}
.msg-footer-select-right {
  display: flex;
}
.msg-select-button {
  height: 100%;
  aspect-ratio: 1/1;
}

.msg-footer-select-left > span {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5%;
  color: black;
}
.reply-input-container {
  display: flex;
  width: 100%;
  height: 100%;
}
