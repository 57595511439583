.wrapper-filter-list-item {
  width: 100%;
  height: 81vh;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  position: absolute;
  z-index: 100;
  background-color: white;
}
.wrapper-editor-date {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}
