.new-find-user {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.wrapper-logo-pen {
  display: flex;
  width: 100%;
  height: 50%;
}

.wrapper-logo-name {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  width: 70%;
}
.user-name {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  align-items: center;
  color: black;
  width: 100%;
  height: 20%;
}
.user-logo {
  display: flex;
  width: 70%;
  height: 80%;
  justify-content: flex-end;
  align-items: flex-end;
}
.user-logo > img {
  height: 50%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: white;
}

.wrapper-edit-pen {
  display: flex;
  gap: 17%;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  width: 30%;
}

.button-edit-pen {
  height: 15%;
  aspect-ratio: 1/1;
}

.wrapper-mail {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  padding-top: 5%;
  color: black;
}

.wrapper-message {
  display: flex;
  width: 100%;
  height: 7%;
}
.wrapper-message > input {
  width: 83%;
  height: 100%;
}
.button-message-send {
  height: 100%;
  aspect-ratio: 1/1;
}
.link-mail {
  display: none;
}
