.wrapper-trust-contact {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  align-items: center;
  justify-content: space-around;
}
.trust-contact {
  display: flex;
  flex-direction: column;
  gap: 5%;
  height: 35%;
  width: 100%;
}
.trust-contact-seted > img {
  height: 100%;
  object-fit: contain;
}
.trust-contact > input {
  height: 15%;
  width: 100%;
}

.trust-contact > span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 15%;
  width: 100%;
  border: 1px solid black;
  color: black;
}

.wrapper-trust-contact-button {
  display: flex;
  width: 100%;
  height: 20%;
  gap: 10%;
}
.trust-contact-button {
  width: 45%;
}
.finish-contact-button {
  width: 90%;
  height: 7%;
}
.trust-contact-button-image {
  display: flex;
  align-self: center;
  justify-self: center;
  width: 80%;
  height: 100%;
}
.wrapper-button-edit {
  height: 100%;
  aspect-ratio: 2/1;
}
.trust-contact-seted {
  height: 15%;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.trust-contact-privilege {
  display: flex;
  width: 100%;
  height: 50%;
}
