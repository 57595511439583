.add-folder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2%;
  color: #111;
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  background-color: #c5c0c0;
}

.add-folder-header {
  height: 10%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.add-folder-header-btn {
  height: 60%;
  aspect-ratio: 1/1;
}

.add-folder > input[type="text"] {
  padding: 0 2px;
  width: 100%;
  height: 4vh;
  background: transparent;
  outline: none;
}

.custom-file-upload {
  padding: 2% 0;
  width: 100%;
  border: 1px solid #111;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin: 5% 0;
  cursor: pointer;
}

.custom-file-upload > span {
  width: 70%;
  text-align: center;
  font-size: 12px;
}

input[type="file"] {
  display: none;
}

.document-update-btn {
  height: 5vh;
}
