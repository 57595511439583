:root {
  --background-color: #f9f9f9ff;
  --border-dark: #999999ff;
  --border-light: #f2f2f2ff;
  --validate-valid: #00b055ff;
  --validate-invalid: #ff3519ff;
  --button-idle: #ccccccff;
  --button-active: #e6e6e6ff;
}

body {
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
}

.definePin-container {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 11.11vh);
  height: 100%;
  align-items: center;
  align-self: flex-start;
}
.loggin-pin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.manual {
  width: 100vw;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 50vh;
}

/* Buttons */
.pinBtn {
  /* border-top: var(--border-light) 3px solid;
  border-bottom: var(--border-dark) 3px solid;
  border-left: var(--border-dark) 3px solid;
  border-right: var(--border-light) 3px solid;
  */
  height: 30vh;
  width: 30vh;
  background: var(--button-idle);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.manual img {
  width: 20vh;
  cursor: pointer;
}

.pin img {
  width: 15vh;
}

@media (min-width: 700px) {
  .definePin-container {
    flex-direction: row;
  }

  .manual {
    height: 100vh;
  }
  .pin {
    height: 100vh;
  }
  .unlock-screen {
    align-items: center;
  }
  .manual img {
    width: 20vw;
  }

  .pin button {
    width: 30vw;
    height: 30vw;
  }

  .pin button img {
    width: 18vw;
    height: 18vw;
  }
}

@media (min-width: 500px) and (max-width: 700px) {
  .pin {
    width: 75vw;
  }
}
