.wrapper-language-flag {
  padding-top: 5%;
  display: flex;
  gap: 2%;
  width: 90vw;

  flex-wrap: wrap;
}

.chose-flag {
  display: flex;
  height: 10vh;
  aspect-ratio: 1/1;
}

.add-chose-button {
  display: flex;
  height: 10vh;
  aspect-ratio: 1/1;
}
