* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

line {
  z-index: 300;
}

.unlock-screen-first,
.unlock-screen-second,
.unlock-screen-third,
.unlock-screen-fourth {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: transparent;
  /*pointer-events: none;*/
}

.dot-first {
  background-color: #eee;
  border: rgb(70, 70, 70) solid 7px;
  height: 70%;
  aspect-ratio: 1/1.2;
  border-radius: 45%;
  z-index: 250;
  transition: 0.2s ease;
  cursor: pointer;
}

.dot-third {
  background-color: #eee;
  border: rgb(0, 0, 0) solid 3px;
  height: 70%;
  aspect-ratio: 1/1;
  border-radius: 50%;

  transition: 0.2s ease;
  cursor: pointer;
}

.dot-fourth,
.dot-second {
  background-color: #eee;
  border: rgb(0, 0, 0) solid 3px;
  height: 70%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  z-index: 250;
  transition: 0.2s ease;
  cursor: pointer;
}

.dot-first.active,
.dot-second.active,
.dot-third.active,
.dot-fourth.active {
  background-color: green;
}

.wrapper-first,
.wrapper-third {
  position: relative;
  display: flex;
  height: 100%;
  pointer-events: auto;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.wrapper-fourth,
.wrapper-second {
  position: relative;
  display: flex;
  height: 220px;
  pointer-events: auto;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.unlock-svg-first,
.unlock-svg-second,
.unlock-svg-third,
.unlock-svg-fourth {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
}

.wholeContainer-first {
  width: 242px;
  height: 220px;
  border-radius: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.wholeContainer-third {
  width: 120px;
  height: 15vh;
  border-radius: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.wholeContainer-fourth,
.wholeContainer-second {
  width: 242px;
  height: 220px;
  border-radius: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.dot-container-first,
.dot-container-second,
.dot-container-third,
.dot-container-fourth {
  width: 33%;
  height: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-btn {
  margin: 20px;
  border: 1px solid #111;
  border-radius: 10px;
  outline: none;
  padding: 10px 30px;
  color: #111;
  background-color: #fff;
  cursor: pointer;
  transition: 0.3s ease;
}

.reset-btn:hover {
  color: #fff;
  background-color: #111;
}

.correctPattern {
  width: inherit;
  background-color: #777;
  color: white;
  border: none;
  border-radius: 5%;
  height: 10%;
  text-align: center;
}

.currentPattern {
  width: inherit;
  height: 15%;
  width: 100%;
  text-align: center;
  background-color: #555;
  color: white;
  border: none;
}
