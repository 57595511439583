.languages-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    width: 90vw;
}

.languages-header-container {
    display: flex;
    justify-content: center;
}

.languages-header button {
    background: none;
    border: none;
    height: 100%;
    object-fit: contain;
}

.languages-header img {
    width: 40px;
}

.languages-header-button
{
    width: 50%;
}