.image-document-comment-wrapper {
  position: absolute;
  background-color: #fff;
  top: 0;
  height: 59vh;
  width: 100%;
  z-index: 10;
  border: 1vw solid #c4bebe;
  border-radius: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
