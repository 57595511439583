.image-modal-wrapper {
  position: absolute;
  background-color: #fff;
  top: 0;
  height: 70vh;
  width: 100%;
  z-index: 10;
  border: 1vw solid #c4bebe;
  border-radius: 10px;
  overflow-y: auto;
}

.wrapper-input-picture {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-modal {
  display: flex;
  flex-direction: column;
  background-color: #ebe8e8;
  margin-bottom: 5%;
  width: 100%;
  overflow-y: auto;
  min-height: 40vh;
}

.draft-image {
  margin-top: 2vh;
}
.draft-image:first-child {
  margin-top: 0;
}

.image-container {
  width: 100%;
  overflow-y: auto;
  max-height: 78vh;
}
.image-container:first-child {
  max-height: 70vh;
}

.image-container-finish {
  width: 100%;
  overflow-y: auto;
  max-height: 75vh;
}

.image-container-finish > img {
  width: 100%;
}

.image-container > img {
  width: 100%;
}

.image-modal-settings {
  height: 7vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.image-modal-icon {
  height: 100%;
  width: 20%;
}

.image-modal-icon-double {
  height: 100%;
  width: 40%;
}

.double-icon-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
}

.double-icon-container > img {
  width: 30%;
  height: 70%;
}

.image-modal-name {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-modal-name-input {
  flex: 1;
  height: 100%;
  outline: none;
  padding: 0 1%;
  font-size: 20px;
  text-align: center;
}

.image-modal-span {
  width: 100%;
  text-align: start;
  color: red;
  font-size: 20px;
  padding-left: 2%;
}

.image-modal-input {
  width: 30%;
  height: 10%;
  background-color: gray;
  margin: 10% 0;
}

.image-modal-input-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px 0;
}

.plus-img {
  width: 20%;
  height: 40%;
  margin-right: 10%;
}

.image-img {
  width: 35%;
  height: 60%;
}

.line-image {
  width: calc(100% - 6vh);
  height: 2vh;
  border-bottom: solid;
  margin: 0 3vh;
}
.title-container {
  height: 5vh;
  font-size: 3vh;
  padding-left: 3vh;
}

.image-modal-input > input[type="file"] {
  display: none;
}

@media (orientation: landscape) {
  .image-modal-wrapper {
    left: 1.5%;
    right: unset;
    top: 3%;
    width: 47%;
    height: 93vh;
  }
  .image-modal-wrapper.adding {
    right: 1.5%;
    left: unset;
  }
}

@media (orientation: portrait) {
  .image-container-finish {
    max-height: 65vh;
  }
}
