.wrapper-logo {
  height: 90vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
  gap: 5%;
}

.android-apple {
  display: flex;
  width: 80%;
  height: 20%;
  justify-content: space-between;
}
.android-apple > img {
  height: 100%;
  width: 33%;
  object-fit: contain;
}

.logo {
  width: 90%;
  height: 50%;
}
.logo > img {
  width: 100%;
  height: 100%;
}

.wrapper-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.android-apple img {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  cursor: pointer;
  margin: 10px;
}
.youtube-link {
  display: flex;
  width: 80%;
  height: 20%;
  justify-content: space-between;
}

.youtube-link > img {
  height: 100%;
  width: 33%;
  object-fit: contain;
}

.glow {
  animation: animatePhoneLogo infinite 2s;
}

@keyframes animatePhoneLogo {
  0% {
    filter: invert(25%) sepia(75%) saturate(1000%) hue-rotate(90deg)
      brightness(100%) contrast(100%);
  }
  50% {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%)
      contrast(0%);
  }
  100% {
    filter: invert(25%) sepia(75%) saturate(1000%) hue-rotate(90deg)
      brightness(100%) contrast(100%);
  }
}
