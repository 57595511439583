/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.single-account {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.single-account-key {
  display: flex;
  width: 100%;
  height: 12%;
  gap: 2%;
}

.single-account-key > * {
  width: 20%;
  height: 100%;
  text-align: center;
  font-size: 60px;
  font-weight: 600;
}

.single-account-key > img {
  border: none;
}

.single-account > img {
  height: 20%;
  aspect-ratio: 1/1;
  margin-left: 5%;
}

.account-container {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5%;
}

.account-container > span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-container > span:first-child {
  height: 75%;
  width: 100%;
  font-size: 180px;
  font-weight: 700;
}

.account-container > img:first-child {
  height: 75%;
  width: 100%;
}

.account-container > span:nth-child(2) {
  height: 20%;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
}

.play-button {
  height: 20%;
  aspect-ratio: 1/1;
  margin-left: 5%;
}
.play-button > img {
  width: 100%;
  height: 100%;
}
