.wraper-filter-user {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -40%;
  left: -15vw;
  height: 86%;
  width: 73vw;
  background-color: #c4bebe;
  overflow-y: auto;
}

.wrapper-document-user {
  display: flex;
  flex-direction: column;
  position: absolute;
  /* top: -40%; */
  /* left: -20vw; */
  height: 86%;
  width: 100%;
  background-color: #c4bebe;
  overflow-y: auto;
}

.user-filter-modal-item-button {
  width: 100%;
  height: 7vh;
}

.user-filter-modal-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 5%;
}

.user-filter-modal-item-color {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}
.user-filter-modal-item-letter {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 700;
}
.user-filter-modal-item-name {
  font-size: 15px;
  font-weight: 700;
}
