.wrapper-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.wrapper-header {
  display: flex;
  height: 12%;
  width: 100%;
  gap: 2%;
  padding: 2%;
}

.wrapper-button {
  width: 25%;
  aspect-ratio: 1/1;
}
.wrapper-content {
  display: flex;
  width: 100%;
  height: 90%;
  flex-direction: column;
  gap: 2%;
  padding: 2%;
  overflow-y: scroll;
}
.one-link {
  display: flex;
  height: 10%;
  width: 100%;
}
.open {
  height: 100%;
}
.wrapper-my-link-button {
  display: flex;
  width: 78%;
  justify-content: center;
  align-items: center;
}
.wrapper-link-cancel {
  width: 22%;
}

.wrapper-input-link {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10%;
}
.wrapper-input-link > input {
  width: 78%;
}

.input-name-link {
  width: 100%;
  position: absolute;
  height: 10%;
  bottom: 12%;
}

.wrapper-link-content {
  display: flex;
  height: 100%;
  width: 100%;
}

.wrapper-link-search-content
{  display: flex;
  height: 100%;
  width: 100%;

}

.wrapper-open-share {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10%;
  gap: 2%;
  padding: 2%;
}
.wrapper-link-share-button {
  width: 24%;
  aspect-ratio: 1/1;
}

.wrapper-search-content
{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 2%;
}
.wrapper-search-header
{
  display: flex;
  height: 10%;
  width: 100%;
}
.wrapper-search-header > input
{
  width: 80%;
  height: 100%;
  font-size: 20px;
  font-weight: 700;
}
.wrapper-search-button
{
  width: 20%;
  height: 100%;
}

.wrapper-search-body
{
  width: 100%;
  height: 90%;
}