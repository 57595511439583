:root {
  --background-color: #f9f9f9ff;
  --border-dark: #999999ff;
  --border-light: #f2f2f2ff;
  --validate-valid: #00b055ff;
  --validate-invalid: #ff3519ff;
  --button-idle: #ccccccff;
  --button-active: #e6e6e6ff;
}

body {
  background-color: var(--background-color);
}
.sidebar {
  position: fixed; /* Changed to fixed to ensure it stays in view */
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar.hidden {
  transform: translateX(95%);
}

.sidebar.visible {
  transform: translateX(0);
}

.sidebar button {
  height: 11.11vh;
  width: 11.11vh;
  /*border-top: var(--border-light) 3px solid;
  border-bottom: var(--border-dark) 3px solid;
  border-left: var(--border-dark) 3px solid;
  border-right: var(--border-light) 3px solid;
  background: var(--button-idle);
  */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.sidebar button img {
  width: 70%;
}

@media (min-width: 700px) {
  .sidebar {
    position: fixed; /* Changed to fixed to ensure it stays in view */
    left: 0;
    top: 0;
    z-index: 100;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100vw;
    height: 11.11vw;
  }

  .sidebar button {
    width: 11.11vw;
    height: 11.11vw;
  }

  .sidebar.hidden {
    transform: translateY(-95%);
  }

  .sidebar.visible {
    transform: translateY(0);
  }
}
