.pattern-unlock-wrapper {
  position: relative;
  height: 220px;
}

.password-pattern-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 3% 10%;
  gap: 5%;
}
.input-key-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.counter-visibility-wrapper {
  display: flex;
  height: 20%;
  justify-content: center;
}
.counter-visibility-wrapper > p {
  font-weight: 500;
}

.counter-visibility-wrapper > span {
  display: flex;
  width: 50%;
  align-items: center;
  padding-bottom: 5%;
  justify-content: center;
  font-size: 120px;
  font-weight: 500;
  color: red;
}
.counter-visibility-wrapper > img {
  height: 100%;
  aspect-ratio: 1/1;
  user-select: none;
}
.password-button-edit {
  width: 100%;
  height: 8%;
}
.password-input {
  display: flex;
  align-items: center;

  font-size: 50px;
  justify-content: space-between;
  width: 100%;
  height: 10%;
}
.pattern-unlock {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.password-image-edit {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
}
.pattern-type {
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: center;
  gap: 39px;
  top: 23%;
  z-index: 500;
}
.unlock-screen {
  height: 15vh;
  aspect-ratio: 1/1;
  backdrop-filter: blur(4px);
}
