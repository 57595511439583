:root {
    --background-color: #f9f9f9ff;
    --border-dark: #999999ff;
    --border-light: #f2f2f2ff;
    --validate-valid: #00b055ff;
    --validate-invalid: #ff3519ff;
    --button-idle: #ccccccff;
    --button-active: #e6e6e6ff;
}

button {
    border-top: var(--border-light) 2px solid;
    border-bottom: var(--border-dark) 2px solid;
    border-left: var(--border-dark) 2px solid;
    border-right: var(--border-light) 2px solid;
    background: var(--button-idle);
    width: 100%;
}

.send-code-my-self-input {
    border-top: var(--border-dark) 2px solid;
    border-bottom: var(--border-light) 2px solid;
    border-left: var(--border-light) 2px solid;
    border-right: var(--border-dark) 2px solid;
    height: 10mm;
    margin: 10px 0;
    font-size: 1.3em;
    width: 87vw;
}

.send-code-only-to-myself-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px 40px;
}

.message-top {
    padding: 12px 20px;
    border: 1px solid gainsboro;
}

.send-code-only-to-myself-container button {
    padding: 20px;
    margin-top: 30px;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.send-code-btn {
    width: 100%;
}