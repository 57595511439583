.image-popup-wrapper {
  position: absolute;
  background-color: #fff;
  top: -2%;
  height: 60vh;
  width: 100%;
  z-index: 25;
  border: 1vw solid #c4bebe;
  border-radius: 10px;
  overflow-y: auto;
}

.image-popup-modal {
  width: 100%;

  padding: 2% 0;
}

.image-popup-modal-wrapper {
  overflow-y: auto;
  width: 100%;
}

.image-popup-container {
  min-height: 35vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.image-popup-container.with-image {
  min-height: unset;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1%;
}

.popup-image-wrapper {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.popup-title-container {
  height: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 2%;
}

.popup-image-container-finish > img {
  width: 100%;
}

.popup-input-picture {
  height: 48%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2%;
}

.popup-image-modal-input {
  align-self: center;
  height: 25%;
  width: 25%;
  background-color: #999;
  margin-top: 5%;
}

.popup-image-modal-settings {
  height: 7vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.popup-image-modal-name-input {
  width: 60%;
  height: 100%;
  outline: none;
  padding: 0 1%;
  font-size: 20px;
  text-align: center;
}
