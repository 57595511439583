.login-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 5% 15%;
  gap: 1%;
}

.login-button {
  height: 10%;
  width: 100%;
  margin-top: 5%;
}

.login-wrapper > img {
  align-self: flex-start;
  width: 10%;
  aspect-ratio: 1/1;
  margin-top: 5%;
}

.input-key {
  display: flex;
  width: 100%;
  gap: 1%;
}

.input-key > input {
  width: calc(100% / 6);
  height: 11vw;
  text-align: center;
}

.input-mail {
  width: 100%;
  height: 11vw;
  padding: 0 2%;
}

input:focus {
  outline: none;
}

.register-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2%;
  height: 70%;
  width: 100%;
}

.register-button {
  width: 100%;
  height: 15%;
}

.register-button-img {
  display: flex;
  justify-content: center;
  gap: 5%;
  width: 100%;
  height: 100%;
  align-items: center;
}
.register-button-img > img:first-child {
  height: 40%;
  aspect-ratio: 1/1;
}

.register-text {
  display: flex;
  flex-direction: column;
  gap: 10%;
  height: 35%;
  border: solid #999999ff 2px;
  padding: 3%;
}

.not-correct-content {
  color: red;
}

.countdown-span {
  color: red;
  height: 20%;
  width: 50%;
  margin-bottom: 10%;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
