:root {
  --background-color: #f9f9f9ff;
  --border-dark: #999999ff;
  --border-light: #f2f2f2ff;
  --validate-valid: #00b055ff;
  --validate-invalid: #ff3519ff;
  --button-idle: #ccccccff;
  --button-active: #e6e6e6ff;
}

.check-cor-trusted-contacts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.instruction {
  border: 1px solid gainsboro;
  margin: 20px;
  text-align: center;
  width: 80vw;
}

.btns {
  display: flex;
  width: 100%;
  height: 70%;
}

.btn-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10%;
  height: 12%;
}

.btns button {
  font-size: 2em;
}

.button-trust-contact {
  width: 50%;
  height: 100%;
}

.activeButton {
  border-top: var(--border-dark) 2px solid;
  border-bottom: var(--border-light) 2px solid;
  border-left: var(--border-light) 2px solid;
  border-right: var(--border-dark) 2px solid;
  background: var(--button-active);
}

.trust-contact-input {
  border-top: var(--border-dark) 2px solid;
  border-bottom: var(--border-light) 2px solid;
  border-left: var(--border-light) 2px solid;
  border-right: var(--border-dark) 2px solid;
  height: 10mm;
  margin: 10px 0;
  font-size: 1.3em;
  width: 80vw;
}

.trusted-contact {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}

.trusted-contact img {
  width: 150px;
}

.send-button-truste-contact {
  width: 70vw;
  background-color: red;
  position: absolute;
  bottom: 50px;
}

.send-button-truste-contact button {
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-button-truste-contact img {
  width: 60px;
}
