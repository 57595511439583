.category-modal {
  position: absolute;
  background-color: #fff;
  top: 0;
  height: 71vh;
  width: 100%;
  z-index: 10;
  border: 1vw solid #c4bebe;
  border-radius: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.category-modal-item-button {
  width: 100%;
  height: 15%;
  padding: 2% 5%;
}
.category-modal-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 5%;
  gap: 5%;
}
.category-modal-item-color {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}
.category-modal-item-letter {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 700;
}
.category-modal-item-name {
  font-size: 15px;
  font-weight: 700;
}
.category-modal-item-color-letter {
  width: 50%;
  aspect-ratio: 1/1;
}
