.home-page {
  width: 100%;
  height: 100%;
  padding: 2%;
  display: grid;
  grid-template-columns: repeat(2, 20vh); /* Dve kolone sa jednakom širinom */
  gap: 2%;
  align-self: center;
  justify-self: center;
  justify-content: center;
}

.wrapper-item-button {
  display: flex;
  width: 90%;
  aspect-ratio: 1/1;
  align-self: center;
  justify-self: center;
}
