.slider {
  height: 100%;
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slider-markers {
  width: 100%;
  display: flex;
  position: relative;
  height: 35%;
}

.slider-marker {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 3%;
  background-color: #808080;
  border-radius: 5px;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  cursor: pointer;
}

.slider-marker.start {
  left: 0;
}

.slider-marker.end {
  right: 0;
  transform: rotate(180deg);
}

.slider-line {
  height: 15%;
  width: 100%;
  border: 1px solid #111;
  position: relative;
}

.slider-line-fill,
.slider-line-fill-new {
  height: 100%;
  background-color: green;
  position: absolute;
}

.slider-line-podeok {
  width: 100%;
  display: flex;
  position: absolute;
  height: 100%;
}

.slider-podeok {
  height: 100%;
  aspect-ratio: 1 / 1;
  border: 1px solid #111;
  z-index: 20;
}

.slider-time {
  width: 100%;
  position: relative;
  font-size: 15px;
  margin: 0.5% 0;
  display: flex;
  align-items: flex-end;
}

.slider-time > span {
  position: absolute;
  white-space: normal;
  padding: 0.5%;
  border: 1px solid #111;
  text-align: center;
  font-size: 10px;
}

.span-end {
  top: 5%;
}

.slider-time > img {
  height: 60%;
  aspect-ratio: 1 / 1;
  position: absolute;
  bottom: 0;
}

.slider-shifts {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
