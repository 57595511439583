.wrapper-languages-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90%;
  padding: 5% 10%;
}

.flags-up {
  display: flex;
  height: 25%;
  width: 100%;
  justify-content: center;
}

.flags-center {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50%;
}
.flags-center-side {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 20%;
}

.flags-down {
  position: relative;
  display: flex;
  width: 100%;
  height: 25%;
  justify-content: center;
}
.flags-down-button {
  position: absolute;

  display: flex;
  height: 30%;
  aspect-ratio: 1/1;
}

.flags-down-button > button {
  border-radius: 50%;
}

.one-flag {
  display: flex;
  border: 1px solid black;
  border-radius: 50%;
  height: 60px;
  aspect-ratio: 1 / 1;
}

.one-flag > img,
.one-flag-up > img,
.one-flag-down > img {
  display: flex;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.one-flag-up {
  position: absolute;
  display: flex;
  border: 1px solid black;
  border-radius: 50%;
  height: 60px;
  aspect-ratio: 1 / 1;
}

.one-flag-down {
  position: absolute;
  display: flex;
  border: 1px solid black;
  border-radius: 50%;
  height: 60px;
  aspect-ratio: 1 / 1;
}
.wrapper-select-flag {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10%;
  gap: 10%;
  width: 60%;
  height: 100%;
}

.select-country-button {
  height: 20%;
  width: 100%;
}

.name-country {
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

.image-flag {
  position: absolute;
  top: -15%;
  align-self: center;
  display: flex;
  height: 50%;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
