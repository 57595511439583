.pdf-viewer-main {
  position: absolute;
  top: 15vh;
  left: 5%;
  width: 90%;
  height: 82vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  z-index: 100;
  background-color: #cecbcb;
}
.pdf-viewer-main-filter {
  position: absolute;
  top: 0;
  width: 100%;
  height: 82vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  z-index: 100;
  background-color: #cecbcb;
}

.pdf-viewer-wrapper {
  height: 70%;
  width: 100%;
  overflow-y: auto;
  background-color: #636262;
}

.pdf-viewer {
  height: 100%;
  width: 100%;
}

.pdf-viewer-buttons {
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.pdf-viewer-btn {
  height: 100%;
  width: 20%;
}

.pdf-button {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdf-button > img {
  height: 60%;
  width: 50%;
}

.pdf-button.non-active {
  background-color: red;
}

.pdf-download {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdf-download > img {
  position: absolute;
  height: 60%;
  width: 60%;
}

.pdf-viewer-wrapper > * .mobile__pdf__container #viewerContainer {
  top: 7% !important;
  height: 40vh !important;
}

.pdf-viewer-wrapper > * .mobile__pdf__container__header {
  width: 100%;
  position: absolute;
  top: 0% !important;
}

.pdf-viewer-wrapper > * .mobile__pdf__container footer {
  bottom: 30% !important;
  position: absolute !important;
}

.pdf-viewer-wrapper > * .pdfViewer .canvasWrapper {
  width: unset !important;
  height: unset !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-viewer-wrapper > * .pdfViewer .page {
  border-left: none;
  border-right: none;
}
