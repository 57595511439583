.accounts-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 5%;
  padding: 10%;
  max-height: 100vh;
}
.account-list::-webkit-scrollbar {
  display: none;
}

.account-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 5vh;
}

.account {
  width: 100%;
  aspect-ratio: 1/1;
}
.account-button {
  display: flex;
  flex-direction: column;
  width: 100%;
  aspect-ratio: 1/1;
  justify-content: center;
  align-items: center;
}

.account-button > span {
  width: 100%;
  font-size: 40px;
}

.account-button-image {
  aspect-ratio: unset;
  width: 60%;
  height: 70%;
}

.initial {
  font-size: 100px !important;
  font-weight: 600;
}
.wrapper-location {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 5%;
}

.concret-location {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  overflow-y: auto;
  gap: 5%;
}

.logo-account {
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-account > img {
  height: 100%;
  object-fit: contain;
}

.name-account {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
  width: 100%;
  font-size: 30px;
  font-weight: 700;
}

.location-name {
  display: flex;
  width: 100%;
  height: 30%;
  justify-content: center;
  align-items: center;
}

.location-name-current {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
}
